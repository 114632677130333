/**
 * Lightbox Suport
 *
 * @see https://www.npmjs.com/package/basiclightbox
 * @see https://github.com/electerious/basicLightbox
 *
 * @since 1.0.0
 * @since 1.0.2 drops jQuery dependency and Colorbox
 */

import * as basicLightbox from 'basiclightbox';
import * as helpers from '../global/helpers';
import { breakpoints } from './variables';

/**
 * Add a classname to an element, given that their direct
 * child is an <img /> or <picture> element.
 *
 * @since 1.0.0
 * @since 1.0.1 Stopped using jQuery, now only applies to a single element
 *
 * @param {object} element      Element node
 * @param {String} className 	Class to add to element if direct child is an img or picture element.
 */
export function addImageLinkClass(element, className) {
	if (element.querySelector('img, picture')) {
		element.classList.add(className);
	}
}

/**
 * Generate the close <button> for basicLightBox
 *
 * @since 1.0.0
 *
 * @param {number} index	the current element's index
 */
export function getLightboxCloseButton(index) {
	const button = `<button id="lightbox-${index}" tabindex="1" class="basicLightbox__close no-button-style"><span class="screen-reader-text">Close</span><span class="dashicons dashicons-no-alt"></span></button>`;
	return button;
}

/**
 * Launches links to valid image formats into a lightbox instead of opening within window
 *
 * @see https://github.com/electerious/basicLightbox
 *
 * @since  1.0.1 Uses basicLightbox instead of Colorbox
 */
function initImageLightBox() {
	/**
	 * Query selector for image links.
	 */
	const imageLinks = '[href*=".jpg"],[href*=".gif"],[href*=".png"],[href*=".svg"]';
	const imagesQuery = document.querySelectorAll(imageLinks);

	helpers.forEach(imagesQuery, (index, elem) => {
		/**
		 * Add enlarge class to link if it contains an image.
		 */
		addImageLinkClass(elem, 'enlarge');

		/**
		 * Define our src and alt attributes to use in the <img /> tag
		 */
		const attrSrc = elem.getAttribute('href');
		const attrAlt = elem.firstChild ? elem.firstChild.getAttribute('alt') : "";

		/**
		 * Fetch the markup for the lightbox close button
		 */
		const lightboxCloseButton = getLightboxCloseButton(index);

		/**
		 * Create <img /> to render in the lightbox.
		 */
		const html = basicLightbox.create(
			`${lightboxCloseButton}
			<img src="${attrSrc}" alt="${attrAlt}" />`,
			{
				afterShow: () => { document.getElementById(`lightbox-${index}`).onclick = function () { html.close(); }; },
			},
		);

		/**
		 * Launch the ligtbox on link click and prevent normal link behavior
		 */
		elem.onclick = function (e) {
			e.preventDefault();
			html.show();
		};
	});
}

/**
 * Display YouTube, Vimeo or Wistia videos into a lightbox (via ColorBox)
 * if the viewport is above a certain size and the device is not iOS.
 *
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since 1.0.0
 * @since 1.0.1 Using regex match to check video service and to pull out the video's ID.
 * @since 1.0.2 Uses basicLightbox instead of Colorbox
 *
 */
function initVideoLightbox() {
	/**
	 * Query selector for video links.
	 */
	const videoLinks = '[href*="youtube.com"],[href*="youtu.be"],[href*="vimeo.com"],[href*="wistia.com"],[href*=".mp4"]';

	/**
	 * Regex patterns for matching various video URLs from popular services.
	 */
	const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;
	const vimeoRegex = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/;
	const wistiaRegex = /(?:https?:\/\/(.+)?(wistia.com|wi.st)\/(medias|embed)\/)(.*)/;
	const videoQuery = document.querySelectorAll(videoLinks);

	helpers.forEach(videoQuery, (index, elem) => {
		/**
		 * Only proceed if we are not on a touch device
		 */
		if (helpers.isTouchDevice()) {
			return;
		}

		const href = elem.getAttribute('href');
		let attrSrc = false;
		let iframe = true;

		/**
		 * Add play-btn class to link if it contains an image.
		 */
		addImageLinkClass(elem, 'play-btn');

		/**
		 * Check if the video link is an MP4
		 */
		if (href.indexOf('.mp4') >= 0) {
			attrSrc = href;
			iframe = false;
		}

		/**
		 * Check if there is a match for YouTube that isn't a channel page
		 */
		if (!attrSrc) {
			const youTubeMatches = href.match(youtubeRegex);
			if (youTubeMatches !== null && href.match(/youtube.com\/user|youtube.com\/channel/) === null) {
				attrSrc = `https://www.youtube.com/embed/${youTubeMatches[1]}?autoplay=1&hd=1&rel=0`;
			}
		}

		/**
		 * Check if there is a match for Vimeo
		 */
		if (!attrSrc) {
			const vimeoMatches = href.match(vimeoRegex);
			if (vimeoMatches !== null) {
				attrSrc = `https://player.vimeo.com/video/${vimeoMatches[4]}?autoplay=1`;
			}
		}

		/**
		 * Check if there is a match for Wistia
		 */
		if (!attrSrc) {
			const wistiaMatches = href.match(wistiaRegex);
			if (wistiaMatches !== null) {
				attrSrc = `https://fast.wistia.net/embed/iframe/${wistiaMatches[4]}?autoplay=1`;
			}
		}

		/**
		 * Only proceed if we have a valid src attribute for an iframe
		 */
		if (!attrSrc) {
			return;
		}

		/**
		 * Set lightbox width based on viewport size.
		 */
		let attrWidth = 853;
		if (breakpoints.lg.aboveMin()) {
			attrWidth = 980;
		} else if (breakpoints.xs.belowMax()) {
			attrWidth = 500;
		} else if (breakpoints.sm.belowMax()) {
			attrWidth = 650;
		}

		/**
		 * Set the lightbox height based on a 16:9 aspect ratio.
		 */
		const attrHeight = attrWidth * (9 / 16);

		/**
		 * Fetch the markup for the lightbox close button
		 */
		const lightboxCloseButton = getLightboxCloseButton(index);

		/**
		 * Determine if the video should be renderd in an <iframe> or <video> element
		 * MP4 need to be rendered in a <video> tag to prevent IE11 from prompting to open/download
		 *
		 * @see https://github.com/emagineusa/embark/issues/24
		 */
		let markup = '';
		if (iframe) {
			markup = `<iframe width="${attrWidth}" height="${attrHeight}" src="${attrSrc}" frameborder="0" allowfullscreen></iframe>`;
		} else {
			markup = `<video controls src="${attrSrc}" width="${attrWidth}"></video>`;
		}

		/**
		 * Create <iframe> to render in the lightbox.
		 */
		const html = basicLightbox.create(
			`${lightboxCloseButton} ${markup}`,
			{
				afterShow: () => { document.getElementById(`lightbox-${index}`).onclick = function () { html.close(); }; document.querySelector('.basicLightbox video').play(); },
			},
		);

		/**
		 * Open the video in a lightbox when the link is clicked.
		 */
		elem.onclick = function (e) {
			// Prevent default link behavior
			e.preventDefault();
			html.show();
		};
	});
}

/**
 * Run our lightbox functions once the DOM has been completely loaded.
 */
document.addEventListener('DOMContentLoaded', () => {
	initImageLightBox();
	initVideoLightbox();
});
