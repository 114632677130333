/**
* This file uses calls AOS for Front Page animation
*/

/**
* Imports jQuery.
*
* @see http://api.jquery.com/
*
* @since 1.0.0
*/
import $ from 'jquery';

/**
* Imports slick slider.
*
* @see http://kenwheeler.github.io/slick/
*
* @since 1.0.0
*/
import all from 'slick-carousel';

/**
* A collection of helper functions for use throughout the project.
*
* @since 1.0.0
*/
import * as helpers from '../global/helpers';


// set active transition to false to start.
let activeTransition = false;

function animateHeroArrow() {
	$('.scroll-down').css({ opacity: '1', bottom: '75px' });
}

function animateSlide() {
	$('.slick-current').find('.fp-title-block').delay(500).css('top', '0');
	$('.slick-current').find('.fp-content-block').delay(500).css('top', '0');
	$('.slick-slide').not('.slick-current').find('.fp-content-block').delay(500)
		.css('top', '-100%');
	$('.slick-slide').not('.slick-current').find('.fp-title-block').delay(500)
		.css('top', '100%');
}

function demoTest() {
	console.log('demoTest');
}

function initFPAnimation() {
	const windowWidth = $(window).width();

	if (windowWidth > 1024) {
		// Initialize slick slider.
		const slider = $('.front-page-slideshow');

		if (!$('#front-page-slideshow').hasClass('slick-initialized')) {
			console.log('slick initialized');
			slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				customPaging(slider, i) {
					const title = $(slider.$slides[i]).find('.slide').data('title');
					if (title) {
						return `<a href="#"><span class="dot-title">${title}</span><span class="dot"></span></a>`;
					}
				},
				arrows: false,
				vertical: true,
				infinite: false,

			});

			setTimeout(() => {
				animateHeroArrow();
			}, 2200);
		}

		$('.scroll-down').on('click', () => {
			slider.slick('slickNext');
			setTimeout(() => {
				animateSlide();
			}, 100);
		});

		$('.slick-dots li a').on('click', () => {
			setTimeout(() => {
				animateSlide();
			}, 100);
		});

		// Bind slide event to mousewheel.
		$('#front-page-slideshow').bind('mousewheel DOMMouseScroll onmousewheel touchmove scroll MozMousePixelScroll wheel', slideScroll);
	} else { // if width is less than 1024.
		if ($('#front-page-slideshow').hasClass('slick-initialized')) {
			$('#front-page-slideshow').slick('unslick');
			$('#front-page-slideshow').unbind('mousewheel DOMMouseScroll onmousewheel touchmove scroll MozMousePixelScroll wheel', slideScroll);
			console.log('slick unslicked.');
			// apply bg image data attribute to style tag when unslicked (slick slider removes style attribute).
			$('.slide').each(function (i) {
    	const bgImage = $(this).attr('data-bgimage');
				$(this).css({ 'background-image': `url("${bgImage}")` });
			});
		}
	}
}

function slideScroll(event) {
	const slider = $('.front-page-slideshow');

	// how many slides we got?
	const countSlides = $('div.slide').length;
	const numSlides = countSlides - 1; // Account for the 0 index.

	// get current active slide index.
	const activeSlide = $('.slick-current').attr('data-slick-index');

	if (activeSlide == 0) { // first slide.
		if (event.originalEvent.wheelDelta >= 1 || ( $('body').hasClass('firefox') && event.originalEvent.deltaY <= 1 ) ) {
			event.preventDefault();
		} else if (event.originalEvent.wheelDelta <= 0 ||  ( $('body').hasClass('firefox') &&event.originalEvent.deltaY >= 0 ) ) {
			event.preventDefault();

			if (activeTransition == false) {
				slider.slick('slickNext');
				setTimeout(() => {
					animateSlide();
				}, 100);

				activeTransition = true;
				setTimeout(() => {
					activeTransition = false;
				}, 1000);
			}
		}
	} else if (activeSlide == numSlides) { // last slide.
		if (event.originalEvent.wheelDelta >= 1 || ($('body').hasClass('firefox') && event.originalEvent.deltaY <= 1 ) ) {
			if (activeTransition == false) {
				// lets bring it back up in case the footer is in view.
				$('html, body').animate({ scrollTop: 0 }, 'fast');
				slider.slick('slickPrev');
				setTimeout(() => {
					animateSlide();
				}, 100);

				activeTransition = true;
				setTimeout(() => {
					activeTransition = false;
				}, 1000);
			}
		} else if (event.originalEvent.wheelDelta <= 0 || ($('body').hasClass('firefox') && event.originalEvent.deltaY >= 0 )) {
			if (activeTransition == true) {
				event.preventDefault();
			}
		}
	} else { // all other slides.
		if (event.originalEvent.wheelDelta >= 1 || ($('body').hasClass('firefox') && event.originalEvent.deltaY <= 1 )) {
			event.preventDefault();
			if (activeTransition == false) {
				slider.slick('slickPrev');
				setTimeout(() => {
					animateSlide();
				}, 100);

				activeTransition = true;
				setTimeout(() => {
					activeTransition = false;
				}, 1000);
			}
		} else if (event.originalEvent.wheelDelta <= 0 || ($('body').hasClass('firefox') && event.originalEvent.deltaY >= 0 )) {
			event.preventDefault();
			if (activeTransition == false) {
				slider.slick('slickNext');
				setTimeout(() => {
					animateSlide();
				}, 100);

				activeTransition = true;
				setTimeout(() => {
					activeTransition = false;
				}, 1000);
			}
		}
	}
}

$(window).smartresize(() => {
	initFPAnimation();
});

document.addEventListener('DOMContentLoaded', initFPAnimation);
