/**
 * Layer Type: Sitewide speedbumps
 *
 * @package  Corcept
 * @since    1.1.8
 */

import 'jquery-colorbox';

// Speed Bump - Notify user they are leaving site
const initSpeedBump = function () {
	let html;
	jQuery('[target="_blank"]').on('click', function () {
		const href = jQuery(this).attr('href');

		// list of urls for speedbump checking
		const urls = [
			'corcept.com',
			'corcept.korlymstaging.wpengine.com',
			'korlym.local/corcept',
		];

		// check if link is on same domain.
		if (href.indexOf(window.location.hostname) > 0) {
			return;
		}

		if (jQuery(this).parent().hasClass('no-sb') || jQuery(this).hasClass('no-sb') || urls.indexOf(window.location.href) > 0) {
			// don't show speed bump
			return;
		}


		// Display Default speed bump
		html = 	`${'<div class="speed-bump editor-styles">' +
						'<h3>You are about to leave Corcept’s website and enter a linked site.</h3>' +
						'<p>This link is provided for your convenience and should not be considered an endorsement of the products, services or information provided, or an assurance of the security or privacy provided at the linked site.</p>' +
						'<p style="text-align:center; font-weight: 600;">Would you like to continue?</p>' +
						'<p style="text-align:center"><a target="_blank" href="'}${href}">Continue</a> &nbsp; <a href="javascript:jQuery.colorbox.close();">Cancel</a></p>` +
					'</div>';


		jQuery.colorbox({
			close: '',
			html,
			width: 800,
			height: 320,
			maxWidth: '90%',
			maxHeight: '90%',
		});

		return false;
	});
};

jQuery(document).on('ready', () => { initSpeedBump(); });
