/**
 * The main js file for the theme
 *
 * This is the entry point where all modules are imported and compiled.
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Polyfills for features not supported by all browsers.
 *
 * @since 1.0.1
 */
import './modules/theme/polyfills';

/**
 * Font-loading with 'fontfaceobserver'.
 *
 * @since 1.0.0
 */
import './modules/theme/webfonts';

/**
 * Lazy-load images with 'vanilla-lazyload',
 * and initialize picturefill after they've loaded.
 *
 * @since 1.0.0
 */
import './modules/theme/images';

/**
 * Add classes to image and video thumbnail links, and setup
 * click events to open the image/video in a Colorbox modal.
 *
 * @since 1.0.0
 */
import './modules/theme/lightboxes';

/**
 * Make file inputs and select elements easier to style.
 *
 * @since 1.0.0
 */
import './modules/theme/forms';

/**
 * Scroll to hash on click. Scroll to hash on load.
 *
 * @since 1.0.0
 */
import './modules/theme/scroll-to';

/**
 * Toggle 'scrolled' class on sticky header.
 *
 * @since 1.0.0
 */
import './modules/theme/sticky-header';

/**
 * Toggle 'scrolled' class on sticky header.
 *
 * @since 1.0.0
 */
import './modules/theme/main-menu';

/**
 * Spotlight slider.
 *
 * @since 1.0.0
 */
import './modules/theme/block-spotlight-slider';

/**
 * Toggle search box.
 *
 * @since 1.0.0
 */
import './modules/theme/header-search';

/**
 * Add smart resize function.
 *
 * @since 1.0.0
 */
import './modules/theme/smartresize';

/**
 * Front Page Slide Animation
 *
 * @since 1.0.0
 */
import './modules/theme/fp-animation-roundone';

/**
 * Publications page functions
 *
 * @since 1.0.0
 */
import './modules/theme/publications';

/**
 * Google Map acf output
 *
 * @since 1.0.0
 */
import './modules/theme/google-map';

/**
 * Greenhouse JSON
 *
 * @since 1.0.0
 */
import './modules/theme/greenhouse';

/**
 * Speed Bump
 *
 * @since 1.1.8
 */
import './modules/theme/speed-bump';

/**
 * EM Panels
 *
 * @since 1.1.8
 */
 import './modules/theme/em-panel';
