/**
 * Forms
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Imports jQuery Uniform.
 *
 * A jQuery plugin to make your form controls look how you want them to.
 *
 * @see https://www.npmjs.com/package/jquery-uniform
 *
 * @since 1.0.0
 */
import uniform from 'jquery-uniform';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els, breakpoints } from './variables';

/**
 * Run .uniform() on file upload fields and wrap select elements
 * in a parent container for easier styling.
 *
 * @since  1.0.0
 */
function initPrettyForms() {
	/**
	 * Run uniform on upload elements only.
	 */
	if ($('[type="file"]').length) {
		$('[type="file"]').each(function () {
			const $this = $(this);

			/**
			 * If .uploader is not the parent, add it.
			 */
			if (!$this.parent().hasClass('uploader')) {
				const classes = $(this).attr('class');
				$this.uniform();
				$this.removeClass(classes).parents('.uploader').addClass(classes);
			}

			/**
			 * Fixes issue on Windows Chrome where 'ginput_container_fileupload' class is not being appended
			 */
			$this.closest('.ginput_container').addClass('ginput_container_fileupload');
		} );
	}

	/**
	 * Fixes weird bug in iOS where long options get ellipsis.
	 *
	 * @see https://stackoverflow.com/questions/19398154/how-to-fix-truncated-text-on-select-element-on-ios7
	 */
	$('select').append('<optgroup label=""></optgroup>');
}

/**
 * Scroll to gravity forms errors.
 *
 * @since  1.0.0
 */
function scrollToFormErrors() {
	const formsWithErrors = document.querySelectorAll('.gform_validation_error');
	if (formsWithErrors.length > 0) {
		window.addEventListener('load', (e) => {
			helpers.scrollToEl($(formsWithErrors[0]), els.header.offsetHeight);
		});
	}
}

document.addEventListener('DOMContentLoaded', initPrettyForms);
document.addEventListener('DOMContentLoaded', scrollToFormErrors);
// ! Re-run Uniform functions on gravity forms reloads (without page refresh).
$(document).on('gform_post_render', initPrettyForms);
