(function ($) {
	// Global variables.
	const listingContainer = $('.media-list');
	const filterContainer = $('.filter-form');

	const pagination = document.querySelector('.pagination .page-numbers-container');
	const paginationPrev = document.querySelector('.pagination-prev');
	const paginationNext = document.querySelector('.pagination-next');

	const search = document.querySelector('.search-field-publications');
	let prevSearch = '';
	const aors = $('.publication-filter');

	let selectedAORs = [];

	const initResourceFilters = function () {
		let canBeLoaded = true;

		// Update the posts within the listing container, based on results from the REST API.
		const updateResults = function (results) {
			listingContainer.empty();

			for (let i = 0; i < results.response.length; i++) {
				const result = results.response[i];
				let linkText = result.linktext;
				if (!linkText.length) {
					linkText = 'Read More';
				}
				let target = '';
				if (result.type === 'custom') {
					target = '_blank';
				}
				let ulContent = '';
				if (result.author.length > 0) {
					ulContent += `<li>${result.author}</li>`;
				}
				if (result.year.length > 0) {
					ulContent += `<li>${result.year}</li>`;
				}
				if (result.pmid.length > 0) {
					ulContent += `<li>${result.pmid}</li>`;
				}
				let aorContent = '';
				const aorArray = [];
				if (result.researcharea !== false) {
					aorContent = '<div class="block-media__label">';
					result.researcharea.forEach(area => aorArray.push(area.name));
					aorArray.sort();
					aorContent += aorArray.join(' | ');
					aorContent += '</div>';
				}

				const resultHTML = '<div class="block-media block-media--publication media-object">' +
							'<div class="block-media__body">' +
							`${aorContent}` +
							'<h5 class="block-media__headline" itemprop="headline">' +
								`<a href="${result.url}" itemprop="url" rel="bookmark" target="${target}">${result.title}</a>` +
							'</h5>' +
							'<ul class="meta">' +
								`${ulContent}` +
							'</ul>' +
							'<div class="block-media__excerpt" itemprop="description">' +
								`<p>${result.excerpt}</p>` +
							'</div>' +
							'<div class="block-media__excerpt" itemprop="description">' +
								`<p>${result.details}</p>` +
							'</div>' +
							`<a href="${result.url}" class="btn-text btn-text--arrow" itemprop="url" rel="bookmark" target="${target}">${linkText}</a>` +
						'</div>' +
					'</div>';
				$(resultHTML).appendTo(listingContainer);
			}
		};

		// Helper function to hold the enabled filters.
		const getResourceFilters = function () {
			const filters = {
				aors: selectedAORs,
			};

			return filters;
		};

		// Returns the parameters used to query the REST API.
		const getSearchParams = function () {
			const searchInput = search != undefined ? search.value : null;
			const filters = getResourceFilters();

			// Categories.
			const aorVal = [];
			const aorName = [];

			Object.keys(filters.aors).forEach((key) => {
				aorVal.push(filters.aors[key].checked);
				aorName.push(filters.aors[key].name);
			});

			const aorValJoined = aorVal.join(',');

			// Data: the value of each of the selected options, used in searching for resources.
			const data = {
				search: searchInput,
			};
			if (aorValJoined) data.aor = aorValJoined;

			// Names: the filter-friendly name of the value for the filter listings.
			const names = {
				search: searchInput,
				aor: aorName,
			};

			// Storing the old search value for comparison if the user searches for resources again.
			prevSearch = search.value;

			return data;
		};

		// Updates the pagination at the bottom of the page, based on the number of results.
		const updatePagination = function (results, pageNumber) {
			if (results.length >= 1 || results !== 'null') {
				const maxPages = results.totalPages;
				if (maxPages === 0 || maxPages === 1) {
					$(pagination).html('');
					$(paginationPrev).html('');
					$(paginationNext).html('');
				} else {
					let paginationHTML = '';
					let previous_page = '';
					let next_page = '';

					const currentPage = parseInt(pageNumber || 1);

					// Previous Link.
					for (var i = 1; i <= maxPages; i++) {
						if (currentPage != 1 && maxPages > 1 && (i - 1) != 0) {
							previous_page = (`<span class="prev page-numbers ajax-pagination" data-page="${currentPage - 1}"><i class="fa fa-angle-left" aria-hidden="true"></i> PREVIOUS</span>`);
						}
					}

					// Link to the first page of results.
					if (currentPage != 1) {
						paginationHTML += `<span class="page-numbers ajax-pagination ${(currentPage === 1) ? 'current' : ''}" data-page="${1}">${1}</span>`;
					}

					// ... Split between page numbers.
					if (currentPage - 2 >= 2) {
						paginationHTML += '<span class="page-numbers ajax-pagination dots">...</span>';
					}

					// Loads the two previous pages before the current page.
					for (i = currentPage - 2; i < currentPage; i++) {
						if (i > 1 && i > (currentPage - 2)) {
							paginationHTML += `<span class="page-numbers ajax-pagination ${(i === currentPage) ? 'current' : ''}" data-page="${i}">${i}</span>`;
						}
					}

					// Loads the next two pages after the current page.
					for (i = currentPage; i <= currentPage + 2; i++) {
						if (i <= maxPages) {
							if (i !== maxPages) {
								paginationHTML += `<span class="page-numbers ajax-pagination ${(i === currentPage) ? 'current' : ''}" data-page="${i}">${i}</span>`;
							}
						}
					}

					// ... Split between page numbers.
					if (currentPage + 3 != maxPages && currentPage + 3 < maxPages) {
						paginationHTML += '<span class="page-numbers ajax-pagination dots">...</span>';
					}

					// Link to the last page of results.
					paginationHTML += `<span class="page-numbers ajax-pagination ${(maxPages === currentPage) ? 'current' : ''}" data-page="${maxPages}">${maxPages}</span>`;

					// Next page link.
					for (i = 1; i <= maxPages; i++) {
						if (i >= 2 && maxPages > 1 && currentPage != maxPages) {
							next_page = (`<span class="next page-numbers ajax-pagination" data-page="${currentPage + 1}">NEXT <i class="fa fa-angle-right" aria-hidden="true"></i></span>`);
						}
					}


					$(pagination).html(paginationHTML);
					$(paginationPrev).html(previous_page);
					$(paginationNext).html(next_page);
					$('html, body').animate({ scrollTop: 200 }, 1000);
				}
			}
		};

		// Queries the REST API using the search parameters above and returns results on success.
		const findBlogPosts = function (data) {
			if (canBeLoaded === true) {
				const protocol = document.location.protocol;
				const root = document.location.hostname;
				$.ajax({
					url: `${protocol}//${root}/wp-json/em/v2/resources?per_page=10`,
					data,
					type: 'GET',
					dataType: 'json',
					timeout: 10000,
					error() {
						listingContainer.html('<div class="results-message">No resources found.</div>');
						$(pagination).html('');
						$(paginationPrev).html('');
						$(paginationNext).html('');
						canBeLoaded = true;
					},
					success(results, status, xhr) {
						listingContainer.html('<div class="results-message">Loading resources...</div>');
						setTimeout(() => {
							updateResults(results);
							updatePagination(results, data.page);
						}, 500);
						canBeLoaded = true;
					},
				});
			}
		};

		// Adds the selected options in each of the drop downs as content filters on select.
		aors.on('click', function () {
			const input = $(this).find('input[type="checkbox"]');
			const checked = input.val();
			const name = input.attr('name');
			const enabled = { name, checked };
			let data;

			if (input.prop('checked') === true) {
				input.prop('checked', false);
				const removed = name;
				const enabledFilters = $('.enabled-filter');

				enabledFilters.each(function () {
					if (this.textContent === removed) {
						$(this).remove();
					}
				});

				if (filterContainer[0].children.length === 0) {
					$('.saved-filters').css({ display: 'none' });
				}
				if (input.is(':checked')) {
					selectedAORs.push(enabled);
				} else if (selectedAORs.findIndex(filter => filter.name === removed) !== -1) {
					selectedAORs.splice(selectedAORs.findIndex(filter => filter.name === removed), 1);
				}

				data = getSearchParams();
				findBlogPosts(data);
			} else {
				input.prop('checked', true);
				if (input.is(':checked')) {
					selectedAORs.push(enabled);
					if (!$(this).hasClass('enabled-filter')) {
						$(this).addClass('enabled-filter');
					}
				} else {
					selectedAORs.splice($.inArray(enabled, selectedAORs), 1);
				}
				data = getSearchParams();
				findBlogPosts(data);
			}
		});

		const removeOldSearchFilter = function (prevSearch) {
			$('.enabled-filter').each(function () {
				if (this.innerText === prevSearch) {
					$(this).remove();
				}
			});
		};

		// Main search button.
		$('.filter-submit').on('click', () => {
			removeOldSearchFilter(prevSearch);
			const data = getSearchParams();
			findBlogPosts(data);
		});

		// Submit the search form when a user presses enter.
		$('body').on('keydown', (e) => {
			if (e.code === 13 || e.key === 13) {
				removeOldSearchFilter(prevSearch);
				const data = getSearchParams();
				findBlogPosts(data);
			}
		});

		// Updates the page content when clicking to the next page.
		$(document).on('click', '.page-numbers.ajax-pagination', function () {
			if (!$(this).hasClass('dots') && !$(this).hasClass('current')) {
				const data = getSearchParams();
				const paged = $(this).attr('data-page');
				data.page = paged;
				findBlogPosts(data);
			}
		});
		// Updates the page content on load.
		$(document).on('ready', () => {
			$('.enabled-filter').remove();

			const searchInput = null;
			selectedAORs = [];

			$('input[type="checkbox"]').prop('checked', false);

			const data = getResourceFilters();
			const params = getSearchParams(data);
			findBlogPosts(params);
		});
	};

	// To prevent errors on other pages, only run this script on the resource overview page.
	if (document.location.pathname === '/research-pipeline/publications/') {
		document.addEventListener('DOMContentLoaded', initResourceFilters);
	}
}(jQuery));
