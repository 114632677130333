/**
 * Polyfills
 *
 * @see https://www.npmjs.com/package/es6-object-assign
 * @see https://www.npmjs.com/package/svgxuse
 *
 * @package Embark
 * @since   1.0.1
 */

import es6promise from 'es6-promise';
import 'svgxuse';
import es6objectassign from 'es6-object-assign';

es6promise.polyfill();
es6objectassign.polyfill();

/**
 * Cross-browser requestAnimationFrame function.
 *
 * @since 1.0.1
 */
window.requestAnimationFrame = window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (f) { setTimeout(f, 1000 / 60); };
