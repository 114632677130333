/**
 * EM Panel
 *
 * @package Embark
 * @since   1.1.8
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

$('.em-panel__control').append('<span class="chevron"></span>');

$('.chevron').on('click', function(e) {
	var parent = $(this).parent();
	$(parent).attr('aria-expanded', $(parent).attr('aria-expanded') === 'true' ? false : true);
	$(parent).attr('aria-selected', $(parent).attr('aria-selected') === 'true' ? false : true);
	$(parent).siblings().toggleClass('active');
});
