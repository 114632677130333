/**
 * Scroll-to functionality for hash links on the document
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

/**
 * Scroll to hash on load.
 *
 * @since  1.0.0
 */
function scrollToHashOnLoad() {
	if (window.location.hash) {
		// To top right away.
		scroll( 0,0 );
		// Void some browsers issue.
		setTimeout(
			function() {
					scroll( 0,0 );
			}, 1
		);
		var parentPanel = $( window.location.hash ).closest('.em-panel');
		if (parentPanel.length > 0 && ! parentPanel.hasClass('active') ) {
			parentPanel.addClass('active');
			parentPanel.find('.em-panel__control').attr('aria-selected', 'true');
			parentPanel.find('.em-panel__control').attr('aria-expanded', 'true');
		}
		helpers.scrollToEl( $( window.location.hash ), els.header.offsetHeight );
	}
}

/**
 * Scroll to hash on click, and update URL.
 *
 * @since  1.0.0
 */
function scrollToHashOnClick() {
	if (location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' ) && location.hostname == this.hostname) {
		var hash   = this.hash;
		var target = $( hash );
		target     = target.length ? target : $( '[name=' + hash.slice( 1 ) + ']' );
		if (target.length) {
			var parentPanel = target.closest('.em-panel');
			if (parentPanel.length > 0 && ! parentPanel.hasClass('active')) {
				parentPanel.addClass('active');
				parentPanel.find('.em-panel__control').attr('aria-selected', 'true');
				parentPanel.find('.em-panel__control').attr('aria-expanded', 'true');
			}
			helpers.scrollToEl( target, els.header.offsetHeight );
			helpers.addHashToURL( hash );
			return false;
		}
	}
}

window.addEventListener( 'load', scrollToHashOnLoad );
$( document ).on( 'click', 'a[href*="#"]:not([href="#"])', scrollToHashOnClick );

// fix header overlay when required form field is scrolled to.

var delay = 0;
var offset = 150;

document.addEventListener('invalid', function(e){
   $(e.target).addClass("invalid");
   $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - offset }, delay);
}, true);
document.addEventListener('change', function(e){
   $(e.target).removeClass("invalid")
}, true);
