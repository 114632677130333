/**
 * Header Search
 *
 * @package Embark
 * @since   1.0.0
 */

 import * as helpers from '../global/helpers';
 import { els, breakpoints } from './variables';

 /**
  * Screensize that mobile menu is enabled/disabled
  *
  * @type {Int}
  */
 const menuBreakPoint = breakpoints.md.min;

/**
 * Init Header Search Class
 *
 * @since 1.0.0
 */
function initHeaderSearch()
{
		var menuItem       = document.querySelector( '.menu-item-search' ),
		headerSearchButton = menuItem.querySelector( '.search-button' ),
		headerSearchInput  = menuItem.querySelector( '.search-input' ),
		headerSearchForm   = menuItem.querySelector( '.search-form' ),
		headerSearchLabel  = menuItem.querySelector( '.search-form__label' );
	if (window.innerWidth >= menuBreakPoint) {

	if ( headerSearchInput.value ) {
		menuItem.classList.add( 'search-open' );
	}

	headerSearchButton.addEventListener(
		'click', function (e) {

			if ( menuItem.classList.contains( 'search-open' ) && headerSearchInput.value === '' ) {
				e.preventDefault();
				headerSearchInput.classList.add( 'error' );
				headerSearchInput.setAttribute( 'placeholder', 'Enter Search Term' );
			}

			if ( ! menuItem.classList.contains( 'search-open' ) ) {
				e.preventDefault();
				headerSearchInput.focus();
				menuItem.classList.add( 'search-open' );
			}
		}
	);

	headerSearchLabel.addEventListener(
		'click', function (e) {

			if ( menuItem.classList.contains( 'search-open' ) ) {
				menuItem.classList.remove( 'search-open' );
			}

			if ( ! menuItem.classList.contains( 'search-open' ) ) {
				headerSearchInput.focus();
				menuItem.classList.add( 'search-open' );
			}
		}
	);

	document.addEventListener(
		'click', function (e) {
			if ( ! menuItem.contains( e.target )) {
				menuItem.classList.remove( 'search-open' );
			}
		}
	);
} else {
	headerSearchButton.addEventListener(
		'click', function (e) {
			if ( headerSearchInput.value === '' ) {
				e.preventDefault();
				headerSearchInput.classList.add( 'error' );
				headerSearchInput.setAttribute( 'placeholder', 'Enter Search Term' );
			}
		}
	);
}
}


function search_validation( parent ){
	var menuItem = document.querySelector( parent );

	if ( menuItem ) {

		var button = menuItem.querySelector( '.search-button' ),
		input      = menuItem.querySelector( '.search-input' );

		button.addEventListener(
			'click', function (e) {

				if ( input.value === '' ) {
					e.preventDefault();
					input.classList.add( 'error' );
					input.setAttribute( 'placeholder', 'Enter Search Term' );
				}
			}
		);
	}
}

document.addEventListener(
	'DOMContentLoaded', function () {
		initHeaderSearch();
		search_validation( '.menu-item-search-mobile' );
	}
);

window.addEventListener('resize', helpers.debounce(initHeaderSearch, 100, false));
