/**
 * Lazy-loading for images
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Picturefill.
 *
 * A responsive image polyfill.
 *
 * @see https://www.npmjs.com/package/picturefill
 *
 * @since 1.0.0
 */
import 'picturefill';

/**
 * Vanilla Lazy Load.
 *
 * LazyLoad is a fast, lightweight and flexible script that speeds up
 * your web application by loading images only as they enter the viewport.
 * LazyLoad is written in plain (vanilla) Javascript, it supports responsive
 * images, it's SEO friendly and it has some other notable features.
 *
 * @see https://www.npmjs.com/package/vanilla-lazyload
 *
 * @since 1.0.0
 */
import LazyLoad from 'vanilla-lazyload';

/**
 * Object Fit Images.
 *
 * Polyfill object-fit/object-position on <img>: IE9, IE10, IE11, Edge, Safari, ...
 *
 * @see https://www.npmjs.com/package/object-fit-images
 *
 * @since 1.0.0
 */
import objectFitImages from 'object-fit-images';

/**
 * Kick-off lazy-loading, while reevaluating picturefill in the process.
 *
 * @since 1.0.0
 * @since 1.0.1 Added object-fit support to images that aren't lazyloaded.
 */
function initImages() {
	const lazyImageSelector = 'data-original';

	/**
	 * Lazyload all images that have the lazyImageSelector, while reevaulating picturefill,
	 * and running objectFitImages.
	 */
	const lazyload = new LazyLoad( {
		elements_selector: `img[${lazyImageSelector}]`,
		show_while_loading: true,
		callback_set: (img) => {
			picturefill( {
				reevaluate: true,
				elements: [img]
			} );
			objectFitImages( img );
		},
	} );

	/**
	 * Handle object fit images that aren't being lazy loaded.
	 */
	const nonLazyImages = document.querySelectorAll( `img:not([${lazyImageSelector}])` );
	if ( nonLazyImages.length > 0 ) {
		objectFitImages( nonLazyImages );
	}
};

window.addEventListener( 'DOMContentLoaded', initImages );
