/**
 * Greenhouse JSON Integration
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

function initGreenhouseListings() {

	// The Greenhouse API URL.
	var greenhouseURL = 'https://boards-api.greenhouse.io/v1/boards/corcepttherapeutics/departments';

	// Filter Results by Department.
	var departmentOptions = $('#department-filter-options');
	var enabledDepartmentFilter = [];

	var filter = new Object();

	function getGreenhouseData(url, filter) {
		var response = $.ajax({
			url: url,
			type: 'GET',
			dataType: 'json',
			timeout: 10000,
			error: function() {
				console.log('Error! Something went wrong.');
			},
			success: function(results) {
				setTimeout(function () {
					if (filter && filter.options && filter.options.length > 0) {
						filterResults(results, filter);
					} else {
						getCurrentJobs(results);
					}
					populateDepartmentOptions(results);
				}, 500);
			}
		});
	}

	function filterResults(results, filter) {
		switch (filter.type) {
			case 'department' :
				var filteredResults = {
					departments: [],
				};
				(results.departments).forEach(function(department) {
					(filter.options).forEach(function(filter) {
						if ( department.name === filter ) {
							(filteredResults.departments).push(department);
						}
					});
				});
				getCurrentJobs(filteredResults);
				break;
			case 'keyword' :
				var matchedJobs = [];
				var searchedKeyword = filter.options; 
				searchedKeyword.toLowerCase().replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
				(results.departments).forEach(function(department) {
					if (department.jobs) {
						(department.jobs).forEach(function(job) {
							var jobName = (job.title).toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").split(/\s+/);
							if ( $.inArray(searchedKeyword, jobName) !== -1 ) {
								var singleJob = {
									departmentName : (department.name).trim(),
									jobID : job.id,
									jobTitle : job.title,
									location : job.location.name,
								}
								matchedJobs.push(singleJob);
							}
						});
					}
				});
				updateListings(matchedJobs);
				break;
			case 'location' :
				var matchedJobs = [];
				var searchedLocation = filter.options; 
				searchedLocation.toLowerCase().replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
				var regexSearch = new RegExp("\\b" +  searchedLocation + "\\b");
				(results.departments).forEach(function(department) {
					if (department.jobs) {
						(department.jobs).forEach(function(job) {
							var jobLocation = (job.location.name).toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
							if (  jobLocation.search(regexSearch) >= 0 ) {
								var singleJob = {
									departmentName : (department.name).trim(),
									jobID : job.id,
									jobTitle : job.title,
									location : job.location.name,
								}
								matchedJobs.push(singleJob);
							}
						});
					}
				});
				updateListings(matchedJobs);
				break;
		}
	}

	function getCurrentJobs(data) {
		var listings = [];
		(data.departments).forEach(function(department) {
			if ((department.jobs).length > 0) {
				department.jobs.forEach(function(job) {
					var singleJob = {
						departmentName : (department.name).trim(),
						jobID : job.id,
						jobTitle : job.title,
						location : job.location.name,
					}
					listings.push(singleJob);
				});
			}
		});
		updateListings(listings);
	}

	function populateDepartmentOptions(data) {
		departmentOptions.empty();
		(data.departments).forEach(function(department) {
			if ((department.jobs).length > 0) {
				var departmentOptionHTML = '<li><input class="option" value="' + (department.name) + '" name="' + (department.name) + '" type="checkbox" /><label>' + (department.name).trim() + '</label></li>';
				departmentOptions.append(departmentOptionHTML);
			}
		});

		if (enabledDepartmentFilter.length > 0) {
			(enabledDepartmentFilter).forEach(function(filter) {
				$('input:checkbox[value="' + filter + '"]').prop('checked', true);
			});
		}
	}

	function updateListings(listings) {
		var listingContainer = $('#career-opportunities ul');
		var listingCounter = 10;
		listingContainer.empty();
		if (listings.length > 0) {
			listings.forEach(function(listing) {
				listingCounter += 1;
				var resultHTML = '<li class="job-listing">' +
								 '<a href="' + window.location.href + '?gh_jid=' + listing.jobID + '" class="listing-link">' +
								 '<img class="job-listing__logo" src="https://www.corcept.com/wp-content/uploads/sites/3/2018/08/corcept_favicon-150x150.png" />' +
								 '<div class="job-listing__position"><h3>' + listing.jobTitle + '</h3></div>' + 
								 '<div class="position-meta"><div class="position-meta__location">' + (listing.location).replace(', United States', '') + '</div>' +
								 '<div class="position-meta__department">' + listing.departmentName + '</div></div>' +
								 '</a>' +
								'</li>';
				$(resultHTML).appendTo(listingContainer);
			});
			listingContainer.has('li:nth-child(11)').append('<a class="load-more more">Load more listings</a>');	

			$('.load-more').on('click', function() {
				var next = $(this).parent().children('li:visible:last').nextAll();
				next.slice(0,10).show();

				if (next.length <= 5) {
					$(this).hide();
				}
			});
		} else {
			$('<span>Sorry, there are no jobs that match your terms. Please try searching again.</span>').appendTo(listingContainer);
		}
	}

	function getSearchQuery() {
		// Search results for a keyword.
		var keywordSearch = $('#careers-search_keyword').val();

		// Search results for a location.
		var locationSearch = $('#careers-search_location').val();

		if ( keywordSearch ) {
			filter.type = 'keyword';
			filter.options = keywordSearch;
			getGreenhouseData(greenhouseURL, filter);
		}

		if ( locationSearch ) {
			filter.type = 'location';
			filter.options = locationSearch;
			getGreenhouseData(greenhouseURL, filter);
		}
	}

	$('#department-filter-options').on('click', 'li', function(){
		var input = $(this).find('input[type="checkbox"]');
		var checked = input.val();

		if ( input.prop('checked') === true ) {
			input.prop('checked', false);
			if ( input.is(':checked')) {
				enabledDepartmentFilter.push(checked);
			} else {
				enabledDepartmentFilter.splice( $.inArray(checked, enabledDepartmentFilter), 1 );
			}
		} else {
			input.prop('checked', true);
			if ( input.is(':checked')) {
				enabledDepartmentFilter.push(checked);
			} else {
				enabledDepartmentFilter.splice( $.inArray(checked, enabledDepartmentFilter), 1 );
			}
		}

		filter.type = 'department';
		filter.options = enabledDepartmentFilter;

		getGreenhouseData(greenhouseURL, filter);
	});

	$('#department-filter-options').on('click', 'input[type="checkbox"]', function(){
		var input = $(this);

		if ( input.prop('checked') === false ) {
			input.prop('checked', true);
		} else {
			input.prop('checked', false);
		}
	});

	$('#careers-search_submit').on('click', function () {
		getSearchQuery();
	});

	$('body').on('keydown', function(e) {
		if ( e.key === 'Enter' ) {
			getSearchQuery();
		}
	});

	function loadGreenhouse(url) {
		getGreenhouseData(url);
	}

	document.addEventListener('DOMContentLoaded', loadGreenhouse(greenhouseURL));
}

function initGreenhouse() {

	if (window.location.href.indexOf('?gh_jid') > -1) {
		$('<a href="http://corcept.korlymstaging.wpengine.com/careers/current-opportunities/"><i class="fa fa-angle-double-left"></i> Return to all jobs</a>').insertBefore('#grnhse_app');
		$('#grnhse_app').show();
		$('#careers-search-container').hide();
		$('#career-opportunities').hide();
	} else {
		$('#grnhse_app').hide();
		initGreenhouseListings();
	}

}
document.addEventListener('DOMContentLoaded', initGreenhouse);
if ( $('body').hasClass('page-id-312') ) {
	document.addEventListener('DOMContentLoaded', initGreenhouse);
}