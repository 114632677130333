/**
 * A collection of helper functions for use throughout the project.
 *
 * @package Embark
 * @since 1.0.0
 */

import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

/**
 * JQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Tracks the current scroll position on the page.
 *
 * @type {Number}
 */
let currentScrollPoint;

/**
 * The point (in pixels) at which the header class should be toggled.
 *
 * @type {Number}
 */
const headerScrollPoint = 130;

/**
 * Toggle the header scrolled class.
 *
 * @param  {Event} e
 */
function maybeToggleScrolledClass(e) {

	if ( ! $('body').hasClass('home')) {
	/**
	 * Update the current scroll position.
	 *
	 * @type {Number}
	 */
	currentScrollPoint = ( els.html && els.html.scrollTop ) || els.body.scrollTop;

	/**
	 * Check scroll status and toggle .scrolled accordingly.
	 */
	if ( currentScrollPoint >= headerScrollPoint && ! els.html.classList.contains( 'html--menu-open' ) ) {
		els.header.classList.add( 'scrolled' );

		// only show sticky header when user scrolls up.
		var st = $( this ).scrollTop();
		if (st > lastScrollTop) {
			$( '.scrolled' ).css( 'opacity', 0 );
			$( '.scrolled' ).css( 'z-index', -1 );
		} else {
			$( '.scrolled' ).css( 'opacity', 1 );
			$( '.scrolled' ).css( 'z-index', 1000 );
		}
		lastScrollTop = st;

	} else {
		els.header.classList.remove( 'scrolled' );
	}
}
}
var lastScrollTop = 0;

if ( $('body').hasClass('home') ){
$(window).on('beforeunload', function() {
    $(window).scrollTop(0);
});
}

window.addEventListener( 'orientationchange', maybeToggleScrolledClass );
window.addEventListener( 'scroll', maybeToggleScrolledClass );
window.addEventListener( 'load', maybeToggleScrolledClass );
