/**
 * Layer Type: blockSpotlightSliders content with callouts
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import slick from 'slick-carousel';
import * as helpers from '../global/helpers';

/**
 * Slider layers
 * @type {NodeList}
 */
const blockSpotlightSliders = document.querySelectorAll('.block--spotlight-slider');

function handleSpotlightPaging(el, $slider) {
	const pagerIndex = $(el).index(this);
	$slider.slick('slickGoTo', pagerIndex, false);
}

/**
 * Do synchronized slick sliders.
 */
function initBlockSpotlightSliders() {
	$(blockSpotlightSliders).each(function () {
		// This layer instance.
		const $this = $(this);

		// This layer's content slider.
		const $slider = $(this).find('.spotlight-slides');
		const $sliderNav = $(this).find('.spotlight-nav');

		// Initialize slider if more than one slide.
		if ($slider.find('.spotlight-slide').length > 1) {
			// Content slider properties.
			const sliderProps = {
				adaptiveHeight: true,
				arrows: false,
				asNavFor: $sliderNav,
				dots: false,
				draggable: false,
				fade: true,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 799,
						settings: {
							asNavFor: null,
						},
					},
				],
			};

			const sliderNavProps = {
				arrows: true,
				asNavFor: $slider,
				dots: false,
				mobileFirst: true,
				nextArrow: '<button type="button" class="spotlight-nav__control spotlight-nav__control--next"><span class="screen-reader-text">Next</span><i class="fa fa-angle-right"></i></button>',
				prevArrow: '<button type="button" class="spotlight-nav__control spotlight-nav__control--prev"><span class="screen-reader-text">Previous</span><i class="fa fa-angle-left"></i></button>',
				slidesToShow: 1,
				responsive: [
					{
						breakpoint: 799,
						settings: 'unslick',
					},
				],
			};

			// Initialize the content slider.
			$slider.slick(sliderProps);
			$sliderNav.slick(sliderNavProps);

			// On before slide change, update layer class.
			$slider.on('beforeChange', (event, slickInstance, currentSlide, nextSlide) => {
				// Update pager.
				$this.find('.spotlight-nav__item').siblings().removeClass('active');
				$this.find('.spotlight-nav__item').eq(nextSlide).addClass('active');
			});

			// Add active state to first spotlight nav item.
			$('.spotlight-nav__item:first-child').addClass('active');

			function handleSlickOnResize() {
				if ($(window).width() < 800) {
					if (!$sliderNav.hasClass('slick-initialized')) {
						sliderNavProps.initialSlide = $sliderNav.find('.active').index();
						$sliderNav.slick(sliderNavProps);
					}
				} else {
					if ($sliderNav.hasClass('slick-initialized')) {
						console.log($sliderNav.find('.active'));
						$sliderNav.slick('unslick');
					}
					$sliderNav.find('.active').removeClass('active');
					$sliderNav.find('.spotlight-nav__item').eq($slider.find('.slick-active').index()).addClass('active');
				}
			}

			// Initialize pager click events.
			$(document).on('click', '.spotlight-nav__item', function () {
				handleSpotlightPaging(this, $slider);
			});

			window.addEventListener('resize', helpers.debounce(handleSlickOnResize, 100, false));
		}
	});
}

/**
 * Add click event for links to tabs.
 *
 * Inspects the href for ?tab={slideLabelID} and will simulate a click
 * and scroll to the hash if exists.
 */
$(document).on('click', '[href*="tab="]', function () {
	const $this = $(this);
	const queryString = $this[0].search;
	const queryArray = parseQuery(queryString);
	const tabName = queryArray.tab;
	const tab = $(`#${tabName}`);
	// const hash = $this[0].hash;

	if (tab && tab.length > 0) {
		$(tab).click();
	}
});

window.addEventListener('DOMContentLoaded', initBlockSpotlightSliders);
