/**
 * Defines common theme variables needed across multiple js partials
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Breakpoint variables.
 *
 * You can pass your own array of breakpoint objects to match up with the
 * ones you have setup in your SASS files.
 *
 * @since 1.0.0
 *
 * @see ../global/breakpoints.js
 *
 * @type {Breakpoints}
 */
import { Breakpoints } from '../global/breakpoints';

export const breakpoints = new Breakpoints();

/**
 * Common elements referenced throughout the project.
 *
 * @since 1.0.0
 */
export const els = {
	html: document.documentElement,
	body: document.body,
	header: document.getElementById( 'js-site-header' ),
	navContainer: document.querySelector( '.site-header__navigation' ),
	mainMenu: document.querySelector( '.main-menu' ),
	navicon: document.getElementById( 'js-navicon' ),
	naviconTrigger: document.getElementById( 'js-navicon-trigger' ),
	utilities: document.querySelector( '.utilities' )
};
