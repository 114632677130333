/**
 * Main menu functionality for responsive behaviors and dropdowns.
 *
 * @package  Embark
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import { els, breakpoints } from './variables';

/**
 * Menu items that have submenus.
 *
 * @type {NodeList}
 */
const menuItemsWithChildren = els.mainMenu ? els.mainMenu.querySelectorAll('.menu-item-has-children') : null;

/**
 * Screensize that mobile menu is enabled/disabled
 *
 * @type {Int}
 */
const menuBreakPoint = breakpoints.md.min;

/**
 * Class used for menu item triggers.
 *
 * @type {String}
 */
const menuItemTriggerClass = 'menu-item__trigger';

/**
 * Class added to <html> when menu is open.
 *
 * @type {String}
 */
const menuOpenHtmlClass = 'html--menu-open';

/**
 * Class added to navicon when menu is open.
 *
 * @type {String}
 */
const menuOpenNaviconClass = 'navicon__trigger--open';

/**
 * Toggle mobile submenus on click.
 *
 * @param  {Event} e
 */
function handleMenuItemTriggerClick(e) {
	/**
	 * Prevents further propagation of the current event in the capturing and bubbling phases.
	 */
	e.stopPropagation();

	/**
	 * Check if the event target contains the menu item trigger class.
	 */
	if (e.target.classList.contains(menuItemTriggerClass)) {
		/**
		 * Prevent the click from linking to a new page.
		 */
		e.preventDefault();

		if (window.innerWidth < menuBreakPoint) {
			$(e.target.nextElementSibling).slideToggle();
		}
		$(e.target.previousElementSibling).attr('aria-expanded', (index, attr) => (attr === 'true' ? 'false' : 'true'));
		$(e.target).closest('.menu-item').toggleClass('open');
	}
}

/**
 * Maybe close opened submenus.
 */
function maybeCloseSubmenus() {
	if (els.mainMenu) {
		const openMenuItems = els.mainMenu.querySelectorAll('.menu-item.open');
		if (openMenuItems && window.innerWidth >= menuBreakPoint) {
			helpers.forEach(openMenuItems, (i, menuItem) => {
				menuItem.classList.remove('open');
				const submenus = menuItem.querySelectorAll('.sub-menu');
				helpers.forEach(submenus, (j, submenu) => {
					submenu.style.display = '';
				});
			});
		}
	}
}

/**
 * Check if current submenu is fully within the viewport.
 * If not, add a class so that it can be styled accordingly.
 *
 * @param  {Event} e
 */
function checkSubmenuPosition() {
	const submenu = this.querySelector('.sub-menu');
	if (submenu && !helpers.isElementInViewport(submenu) && window.innerWidth >= menuBreakPoint) {
		this.classList.add('submenu-offscreen');
	}
}

/**
 * Add class to the first .current-menu-ancestor to avoid multiple being highlighted.
 *
 * @param  {Event} e
 */
function handleMultipleMenuAncestors() {
	const ancestorItems = document.querySelectorAll('.main-menu > .current-menu-ancestor');
	if (ancestorItems.length > 0) {
		ancestorItems[0].classList.add('current-menu-ancestor--primary');
	}
}

/**
 * Open the mobile menu.
 */
function openMobileMenu() {
	if (!els.naviconTrigger) {
		return;
	}
	els.html.classList.add(menuOpenHtmlClass);
	els.naviconTrigger.classList.add(menuOpenNaviconClass);
}

/**
 * Close the mobile menu.
 */
function closeMobileMenu() {
	if (!els.naviconTrigger) {
		return;
	}
	els.html.classList.remove(menuOpenHtmlClass);
	els.naviconTrigger.classList.remove(menuOpenNaviconClass);
}

/**
 * Toggle the mobile menu.
 */
function toggleMobileMenu() {
	if (els.html.classList.contains(menuOpenHtmlClass)) {
		closeMobileMenu();
	} else {
		openMobileMenu();
	}
}

/**
 * Close the mobile menu when you click off of it.
 *
 * @param  {Event} e
 */
function handleMenuState(e) {
	if (!els.navicon) {
		return;
	}

	/**
	 * If the menu is open, and the event target is the nav or a child of the nav, exit quickly.
	 */
	if (
		els.html.classList.contains(menuOpenHtmlClass) &&
		(e.target === els.navContainer || $(e.target).closest(els.navContainer).length > 0)
	) {
		return;
	}

	/**
	 * If event target is the navicon or any of its children, trigger the toggle method.
	 */
	if (e.target === els.navicon || $(e.target).closest('.navicon__trigger').length > 0) {
		$( ".main-menu" ).prepend( $( ".menu-item-search" ) );
		toggleMobileMenu();
	} else {
		/**
		 * Else, this is not the navicon trigger. Close the menu. User has clicked away.
		 */
		closeMobileMenu();
	}
}

/**
 * Update mobile menu top offset and close opened submenus depending on screensize.
 *
 * @param  {Event} e
 */
function handleMenuOnResize() {
	maybeCloseSubmenus();

	if (window.innerWidth >= menuBreakPoint) {
		if ( $( ".main-menu" ).has( ".menu-item-search" ) ) {
			$( ".utility-menu" ).append( $( ".menu-item-search" ) );
		}
		closeMobileMenu();
	}
}

/**
 * Initialze menu.
 *
 * @param  {Event} e [description]
 */
function initMainMenu() {
	handleMultipleMenuAncestors();

	/**
	 * Add event listeners for menu items with submenus.
	 */
	if (menuItemsWithChildren) {
		helpers.forEach(menuItemsWithChildren, (i, item) => {
			item.addEventListener('mouseenter', checkSubmenuPosition);
			item.addEventListener('click', handleMenuItemTriggerClick);
		});
	}

	// Add "open" class on page load so mobile-menu is expanded to currently viewed sub-menu items.
	if (window.innerWidth < menuBreakPoint) {
		const $currentMenuItems = $('[class*="current"].menu-item-has-children');
		if ($currentMenuItems.length > 0) {
			$currentMenuItems.addClass('open').find('> .sub-menu').show();
		}
	}
}

function noNaviconScroll() {
	$('.navicon').on('click',function(e){
		e.preventDefault();
	});
}

window.addEventListener('DOMContentLoaded', initMainMenu);
window.addEventListener('DOMContentLoaded', noNaviconScroll);
window.addEventListener('resize', helpers.debounce(handleMenuOnResize, 100, false));
document.addEventListener('click', handleMenuState);
