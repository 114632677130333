/**
 * Progressive webfont loading
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Font Face Observer.
 *
 * Font Face Observer is a small @font-face loader and monitor compatible
 * with any web font service. It will monitor when a web font is applied
 * to the page and notify you. It does not limit you in any way in where,
 * when, or how you load your web fonts. Unlike the Web Font Loader Font
 * Face Observer uses scroll events to detect font loads efficiently and
 * with minimum overhead.
 *
 * @see https://www.npmjs.com/package/fontfaceobserver
 * @see https://github.com/bramstein/fontfaceobserver
 *
 * @since 1.0.0
 */
import FontFaceObserver from 'fontfaceobserver';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

/**
 * On first page load, load all fonts using FontFaceObserver and then
 * add the .fonts-loaded class to the html element, and set the emFontsLoaded
 * cookie to true. On subsequent page loads, header.php checks for the cookie,
 * and, if it's set, adds the class serverside. This script will check to for
 * the .fonts-loaded class and see that it's already there and not load the
 * fonts again, since they are cached.
 *
 * @since  1.0.0
 */
(() => {
	if (els.html.classList.contains('fonts-loaded')) {
		return;
	}

	 Promise
		.all([
			new FontFaceObserver('Roboto', {
				weight: 300,
			}).load(null, 6000),
			new FontFaceObserver('Roboto', {
				weight: 400,
			}).load(null, 6000),
			new FontFaceObserver('Roboto', {
				weight: 700,
			}).load(null, 6000),
			new FontFaceObserver('Aileron', {
				weight: 300,
			}).load(null, 6000),
			new FontFaceObserver('Aileron', {
				weight: 400,
			}).load(null, 6000),
			new FontFaceObserver('Aileron', {
				weight: 600,
			}).load(null, 6000),
			new FontFaceObserver('Aileron', {
				weight: 700,
			}).load(null, 6000),
			new FontFaceObserver('Aileron', {
				weight: 900,
			}).load(null, 6000),
		]).then(() => {
			els.html.classList.add('fonts-loaded');
			document.cookie = 'emFontsLoaded=true;';
		});
})(this);
